import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class PacienteService {
    async getPacientesAll() {
        const pacientes = await fetchWrapper.get(`${ruta}/paciente`);
        return pacientes;
    }
    async getPacientes(parametros) {
        const queryParams = parametros
            ? Object.keys(parametros)
                .map(
                    (k) =>
                        encodeURIComponent(k) + "=" + encodeURIComponent(parametros[k])
                )
                .join("&")
            : "";
        const pacientes = await fetchWrapper.get(
            `${ruta}/paciente-todos?` + queryParams
        );
        return pacientes;
    }

    async sendPacienteNuevo(paciente) {
        const nuevoPaciente = await fetchWrapper.post(`${ruta}/paciente`, paciente);
        return nuevoPaciente;
    }

    async deletePaciente(paciente) {
        const pacienteEliminado = await fetchWrapper.delete(
            `${ruta}/paciente/` + paciente.id
        );
        return pacienteEliminado;
    }
    async updatedPaciente(paciente) {
        const pacienteActualizado = await fetchWrapper.put(
            `${ruta}/paciente/` + paciente.id,
            paciente
        );
        return pacienteActualizado;
    }
    async showPaciente(paciente) {
        const pacienteShow = await fetchWrapper.get(`${ruta}/paciente/` + paciente);
        return pacienteShow;
    }

    async getPacienteImages(cliente_id) {
        return await fetchWrapper.post(`${ruta}/paciente/${cliente_id}/images`);
    }
    async uploadImage(datos) {
        return await fetchWrapper.postFiles(`${ruta}/paciente/images/upload`, datos);
    }

    async deleteImage(imagen_id) {
        return await fetchWrapper.delete(`${ruta}/paciente/images/${imagen_id}`);
    }
    async filtrarCumpleanios(datos) {
        return await fetchWrapper.post(`${ruta}/show_cumpleanios`, datos);
      }
      async obtenerCumpleaniosHoyManana() {
        return await fetchWrapper.get(`${ruta}/obtener_cumpleaneros_hoy_y_manana`);
      }
      async obtenerCumpleaniosMes(mes) {
        return await fetchWrapper.get(`${ruta}/obtener_cumpleaneros_mes/${mes}`);
      }
}