<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Cumpleañeros</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Cumpleaños</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div class="flex align-items-center flex-column lg:justify-content-center lg:flex-row">
      <h4><strong>LISTADO DE CUMPLEAÑEROS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="fecha_inicio"><strong>FECHA INCIO: </strong></label>
            <Calendar id="fecha_inicio" v-model="fecha_inicio" :showIcon="true" dateFormat="dd/mm/yy" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_fin"><strong>FECHA FIN: </strong></label>
            <Calendar id="fecha_fin" v-model="fecha_fin" :showIcon="true" dateFormat="dd/mm/yy" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="cumpleanios_mes"><strong>CUMPLEAÑEROS MES: </strong></label>
            <div class="p-inputgroup">
              <Dropdown v-model="mes_cumpleanios" :options="meses" optionLabel="label" placeholder="Seleccione un mes" />
              <Button icon="pi pi-search" class="p-button-primary mr-2" v-tooltip.top="'Buscar'" :loading="enviando"
                :disabled="enviando" @click="cumpleanios_mes" />
            </div>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>ACCIONES: </strong></label>
            <Button label="BUSCAR CUMPLEAÑEROS" icon="pi pi-search" class="p-button-primary mr-2" v-tooltip.top="'Buscar'"
              :loading="enviando" :disabled="enviando" @click="BuscarFiltro" />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable ref="reporte_cumpleanios" key="id" :value="cumpleanios" responsiveLayout="scroll" :loading="enviando"
          class="p-datatable-sm" :filters="buscar" responsive="true" stripedRows showGridlines>
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-end md:align-items-end">
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="buscar['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid">
              Debe aplicar filtros para ver resultados en la tabla!</span>
          </template>
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column class="text-right" field="id" header="CÓD" :sortable="true">
          </Column>
          <Column field="nombre" header="PACIENTE" style="font-weight: bold;"> </Column>
          <Column field="telefono" header="TELÉFONO"> </Column>
          <Column field="fecha_nacimiento" header="F.NACIMIENTO"> </Column>
          <Column field="fecha_nacimiento" header="EDAD">
            <template #body="{ data }">
              {{ edadMesesCliente(data.fecha_nacimiento) }}
            </template>
          </Column>
          <Column field="fecha_nacimiento" header="CUMPLEAÑOS">
            <template #body="{ data }">
              <Tag v-if="cumpleanio(data.fecha_nacimiento).hoy" severity="success" value="Hoy" class="mr-2"></Tag>
              <Tag v-if="cumpleanio(data.fecha_nacimiento).manana" severity="warning" value="Mañana" class="mr-2"></Tag>

              <Tag severity="info" :value="cumpleanio(data.fecha_nacimiento).fecha" class="mr-2"></Tag>
            </template>
          </Column>
          <Column field="sexo" header="SEXO"> </Column>
          <Column field="contactos" header="CONTACTOS"> </Column>
          <Column>
            <template #header>
              <i class="pi pi-cog text-xl ml-2" />
            </template>
            <template #body="slotProps">
              <Button class="p-button-rounded p-button-primary p-button-sm" v-tooltip.top="'Ver Acciones'"
                icon="pi pi-ellipsis-v" @click="$refs['menu' + slotProps.data.id].toggle($event)" />
              <Menu :ref="'menu' + slotProps.data.id" :popup="true" :model="acciones(slotProps.data)">
              </Menu>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import PacienteService from "@/service/PacienteService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      fecha_inicio: new Date().toLocaleDateString().slice(0, 10),
      fecha_fin: new Date(new Date().setDate(new Date().getDate() + 1))
        .toLocaleDateString()
        .slice(0, 10),
      mes_cumpleanios: null,
      meses: [
        { label: "ENERO", value: 1 },
        { label: "FEBRERO", value: 2 },
        { label: "MARZO", value: 3 },
        { label: "ABRIL", value: 4 },
        { label: "MAYO", value: 5 },
        { label: "JUNIO", value: 6 },
        { label: "JULIO", value: 7 },
        { label: "AGOSTO", value: 8 },
        { label: "SEPTIEMBRE", value: 9 },
        { label: "OCTUBRE", value: 10 },
        { label: "NOVIEMBRE", value: 11 },
        { label: "DICIEMBRE", value: 12 },
      ],
      enviando: false,
      cumpleanios: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  pacienteService: null,
  auth: null,
  created() {
    this.pacienteService = new PacienteService();
    this.auth = useAuth();
    this.cumpleanios_hoy_manana();
  },
  methods: {
    cumpleanios_hoy_manana() {
      this.enviando = true;

      this.pacienteService
        .obtenerCumpleaniosHoyManana()
        .then((data) => {
          this.cumpleanios = data.cumpleanios;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    acciones(data) {
      return [
        {
          label: "Ver Detalle",
          icon: "pi pi-fw pi-eye",
          visible: false,
          disabled:
            "Venta Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/cumpleanios/${data.id}/detalle`,
        },
      ];
    },
    edadMesesCliente(fecha) {
      let fecha_nacimiento = new Date(fecha);
      let fecha_actual = new Date();
      let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
      let meses = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
      if (
        meses < 0 ||
        (meses === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
      ) {
        edad--;
        meses += 12;
      }
      return edad + " AÑOS Y " + meses + " MESES";
    },
    cumpleanios_mes() {
      this.cumpleanios = [];
      this.enviando = true;
      this.pacienteService
        .obtenerCumpleaniosMes(this.mes_cumpleanios.value)
        .then((data) => {
          this.cumpleanios = data.cumpleanios;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    //verifica si el cliente esta de cumpleaños
    cumpleanio(fecha) {
      //fecha = "2021-09-15";
      let r = {
        hoy: false,
        manana: false,
        happy: false,
        fecha: null,
      };

      let fecha_actual = new Date();
      let fecha_nacimiento = new Date(fecha + "T00:00:00");

      if (
        fecha_actual.getDate() == fecha_nacimiento.getDate() &&
        fecha_actual.getMonth() == fecha_nacimiento.getMonth()
      ) {
        r.hoy = true;
        r.fecha = "Hoy";
      } else if (
        fecha_actual.getDate() + 1 == fecha_nacimiento.getDate() &&
        fecha_actual.getMonth() == fecha_nacimiento.getMonth()
      ) {
        r.manana = true;
        r.fecha = "Mañana";
      }
      r.fecha = fecha_nacimiento.toLocaleDateString();
      return r;
    },
    BuscarFiltro() {
      this.cumpleanios = [];
      this.enviando = true;
      this.cargarReporteCumpleanios();
    },

    cargarReporteCumpleanios() {
      let datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
      };
      this.pacienteService
        .filtrarCumpleanios(datos)
        .then((data) => {
          this.cumpleanios = data.cumpleanios;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
}
</style>
